<script>
    // Modules
    import { fade } from "svelte/transition";
    // Components
    import Page from "./Components/Design/Page.svelte";
    import { durVal } from "./Components/Functional/SiteLoadCookie.svelte";
    import { Recaptcha, recaptcha, observer } from "svelte-recaptcha-v2";
</script>

<div
    class="svelte-root relative flex flex-col"
    transition:fade="{{ duration: durVal }}"
>
    <Page />
</div>

<style lang="scss" global>@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&family=Fira+Code&display=swap");
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
:global(html),
:global(body) {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden; }

:global(*) {
  box-sizing: border-box; }

:global(code) {
  font-family: "Fira Code", monospace !important; }

:global(.before:before) {
  content: ""; }

:global(.after:after) {
  content: ""; }

:global(.beforeafter:before), :global(.beforeafter:after) {
  content: ""; }

:global(label) {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.9em; }

:global(input),
:global(textarea) {
  border-bottom: 1px solid black;
  font-size: 1.5em !important; }

:global(input) {
  padding-top: 0.55em !important;
  padding-bottom: 0.55em !important; }

:global(label) :global(textarea) {
  border-top: 1px solid #ccc;
  border-left: 1px solid #aaa;
  border-right: 1px solid #aaa;
  resize: none; }

:global(.wpcf7-submit) {
  cursor: pointer;
  transition: 0.125s; }
  :global(.wpcf7-submit:after) {
    content: "\f0a9";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    margin-left: 1.25em; }
  :global(.wpcf7-submit.disabled) {
    cursor: not-allowed;
    color: #aaa;
    background-color: #ccc;
    background-image: none !important; }
  :global(.wpcf7-submit.sent) {
    cursor: not-allowed;
    color: #b3fbb0; }
    :global(.wpcf7-submit.sent:after) {
      content: "\f00c"; }

:global(.wpcf7-response-output) {
  margin: 1.1em 0;
  transform: rotateX(0deg);
  transition: 0.25s; }
  :global(.wpcf7-response-output) :global(span) {
    position: relative;
    padding: 0.75em 1.25em;
    border-radius: 200px;
    color: white;
    transition: 0.5s; }
  :global(.wpcf7-response-output.wpcf7-display-none) {
    transform: rotateX(90deg); }
  :global(.wpcf7-response-output.error-message) :global(span) {
    background-color: #ff6756; }
  :global(.wpcf7-response-output.success-message) :global(span) {
    background-color: #57c853; }
    :global(.wpcf7-response-output.success-message) :global(span:before) {
      content: "";
      position: absolute;
      background-color: transparent;
      border-bottom: 10px solid #57c853;
      border-top: 5px solid transparent;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      top: -0.75em;
      left: 2em;
      width: 0;
      height: 0; }

:global(.required-field) {
  color: #ff6756; }

:global(.project-content) :global(.wp-block-columns) {
  display: flex;
  width: 100%;
  margin: 5em 0; }
  @media all and (max-width: 767px) {
    :global(.project-content) :global(.wp-block-columns) {
      flex-direction: column; } }
  :global(.project-content) :global(.wp-block-columns) :global(.wp-block-column) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%; }
    @media all and (max-width: 767px) {
      :global(.project-content) :global(.wp-block-columns) :global(.wp-block-column) {
        width: 100%; } }
    :global(.project-content) :global(.wp-block-columns) :global(.wp-block-column) :global(h2),
    :global(.project-content) :global(.wp-block-columns) :global(.wp-block-column) :global(h3),
    :global(.project-content) :global(.wp-block-columns) :global(.wp-block-column) :global(h4),
    :global(.project-content) :global(.wp-block-columns) :global(.wp-block-column) :global(h5),
    :global(.project-content) :global(.wp-block-columns) :global(.wp-block-column) :global(h6),
    :global(.project-content) :global(.wp-block-columns) :global(.wp-block-column) :global(p),
    :global(.project-content) :global(.wp-block-columns) :global(.wp-block-column) :global(blockquote) {
      margin: 1em 12.5%; }
    :global(.project-content) :global(.wp-block-columns) :global(.wp-block-column) :global(h2) {
      font-size: 2em;
      font-weight: 500;
      text-transform: uppercase; }
    :global(.project-content) :global(.wp-block-columns) :global(.wp-block-column:first-of-type) :global(figure) {
      padding: 1em 2.5em 1em 0; }
    :global(.project-content) :global(.wp-block-columns) :global(.wp-block-column:last-of-type) {
      color: #fff; }
      :global(.project-content) :global(.wp-block-columns) :global(.wp-block-column:last-of-type) :global(figure) {
        padding: 1em 0 1em 2.5em; }
      @media all and (max-width: 767px) {
        :global(.project-content) :global(.wp-block-columns) :global(.wp-block-column:last-of-type) {
          color: #000; } }

@media (max-width: 767px) {
  :global(.rellax) {
    transform: translate3d(0, 0, 0) !important; } }

:global(.svelte-root) {
  min-height: 100vh; }</style>
